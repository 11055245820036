<template>
  <div reply-editor v-if="value.useComment">
    <button :class="['btn-toggle', open ? 'toggle-open' : '']" @click="toggle">
      댓글 {{ totalSize }}개
      <svg v-if="totalSize > 0" width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1 7.5L7 1.5L13 7.5" stroke="#2C81FF" stroke-width="1.3" />
      </svg>
    </button>
    <div class="holder">
      <ReplyInputBox :limit-length="500" @reply-input="addReply" />
      <div class="list-holder" v-if="list.length > 0 && open">
        <div class="list-items">
          <ReplyList v-for="item in list" :key="item.id" :item="item" @list-update="update" @edit-reply="editReply" @delete-reply="deleteReply" />
        </div>
      </div>
      <div class="paging-holder" v-if="open && list.length > 0">
        <Pagination :option="pageOptions" group="10" @go="updatePage" />
      </div>
      <p class="bottom" v-if="open  && list.length > 0" />
    </div>
  </div>
</template>

<script>
import Pagination from '@shared/components/common/Pagination.vue';
import ReplyList from '@/views/components/newsFeed/feed/ReplyList.vue';
import ReplyInputBox from '@/views/components/newsFeed/feed/ReplyInputBox.vue';

export default {
  name: 'ReplyEditor',
  components: { ReplyList, Pagination, ReplyInputBox },
  props: {
    value: Object,
  },
  data() {
    return {
      cursor: 0,
      size: 10,
      totalSize: 0,
      open: false,
      modify: false,
      list: [],
      pageOptions: {},
    };
  },
  methods: {
    toggle() {
      this.open = !this.open;
    },
    updatePageOption(r) {
      this.pageOptions = {
        pageNo: this.cursor + 1,
        pageCount: Math.ceil(r.totalCount / 10),
      };
    },
    updatePage(pageId) {
      if (this.cursor === pageId - 1) return;
      this.cursor = pageId - 1;
      this.update();
    },
    async update() {
      this.$nextTick(async () => {
        if (this.list.length === 0 && this.pageOptions.pageNo > 1) {
          this.cursor -= 1;
        }

        const r = await this.$services.channel.getFeedThreads({
          routeId: this.$route.params.id,
          feedId: this.value.id,
          cursor: this.cursor,
          size: this.size,
        });

        this.totalSize = r.totalCount;
        this.updatePageOption(r);
        this.list = r.items;
      });
    },
    async addReply(text) {
      await this.$services.channel.addFeedThread({ feedId: this.value.id, text });
      this.newReply = '';
      this.cursor = 0;
      await this.update();
      setTimeout(() => {
        this.open = true;
        this.$parallax.revokeHeight();
      }, 60);
    },
    async editReply(id, text) {
      await this.$services.channel.editFeedThread({ threadId: id, text });
      await this.update();
      this.$toast('toast.replyEditSuccess', { type: 'success' });
    },
    async deleteReply(id) {
      await this.$services.channel.deleteFeedThread(id);
      await this.update();
      this.$toast('toast.replyDeleteSuccess', { type: 'success' });
      this.$parallax.revokeHeight();
    },
  },
  created() {
    this.update();
  },
};
</script>

<style lang="less">
@import '~@/less/proj.less';

[reply-editor] {.rel; .pb(20);.crop;
  .btn-toggle {.c(#2c81ff);.fs(12);.rel;
    svg { .ib; .wh(12, 6); .mb(3); transform: rotate(180deg);transition: all ease 0.25s; .vam;
      > path { .vam; }
    }
  }
  .toggle-open {
    svg {transform: rotate(360deg);.lt(50, -4);}
  }
  .list-items { .-t(#ebebf0); }
  .holder {
    > [reply-input-box] {
      .btn-holder {
        .btn-cancel { .hide; }
      }
    }
  }
  .paging-holder {.w(100%);
    [pagination] {.mt(28);
    }
  }

  @media (@tl-up) {
    .btn-toggle { .fs(13); }
  }
}
</style>
