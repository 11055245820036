<template>
  <div feed-editor v-if="model">
    <h3>피드 작성</h3>
    <TabSelect :items="['공지', '이벤트']" @input="resetType" v-model="typeId" />
    <TextInput class="wf" placeholder="제목을 입력해주세요." mandatory v-model="model.title" />
    <TextBox placeholder="피드 내용을 입력해주세요." v-model="model.text" max-length="3000" />
    <TextInput class="wf link" placeholder="연결 링크를 입력해주세요." v-model="model.link" />
    <div class="bts-ctrl">
      <ImageInput class="btn-pic" v-model="model.img" type="source" feed />
      <button v-if="isVoteInit && !noVote" class="btn-vote" @click="openVoteModal">{{ voteButtonName }}</button>
      <p>댓글 기능
        <ToggleButton v-model="model.useComment" />
      </p>
    </div>
    <button v-if="!edit" class="btn-opened" @click="registration(false)">게시</button>
    <button v-if="edit" class="btn-cancel" @click="$emit('cancel')">취소</button>
    <button v-if="edit" class="btn-confirm" @click="registration(true)">확인</button>
  </div>
</template>

<script>
import _get from 'lodash/get';
import ImageInput from '@shared/components/common/input/ImageInput.vue';
import TextBox from '@shared/components/common/input/TextBox.vue';
import TextInput from '@shared/components/common/input/TextInput.vue';
import CreateVoteModal from '@shared/components/vote/modal/CreateVoteModal.vue';
import { VOTE } from '@shared/data/constants/vote';
import _ from 'lodash';
import Specific from '@shared/types/Specific';
import TabSelect from '@/views/components/common/input/TabSelect.vue';
import ToggleButton from '@/views/components/common/ToggleButton.vue';

export default {
  name: 'FeedEditor',
  components: { TextInput, TabSelect, TextBox, ToggleButton, ImageInput },
  props: {
    specialId: String,
    value: Specific,
    edit: { type: Boolean, default: false },
    noVote: { type: Boolean, default: false },
  },
  data() {
    return {
      model: null,
      typeId: 0,
      voteInfo: null,
      isVoteInit: true,
    };
  },
  watch: {
    value: 'updateModel',
  },
  computed: {
    checkedFill() {
      return !this.model.text.trim().length && !(_get(this.model, 'img.sourceUrl', '') || '').length;
    },
    voteBase() {
      return { specialId: this.specialId, id: this.model?.id, type: 'LVUP_SPECIAL_FEED' };
    },
    modifyVote() {
      return this.voteInfo?.server?.voteId;
    },
    voteButtonName() {
      return this.modifyVote ? '투표수정' : '투표추가';
    },
  },
  methods: {
    input() {
    },
    updateModel() {
      if (this.value) {
        this.model = this.value;
        this.model.img = this.model.img || {};
        this.model.image = this.model.image || '';
        this.model.category = this.model.category || 'NOTICE';
        this.typeId = this.model.category === 'NOTICE' ? 0 : 1;
      } else {
        this.model = { text: '', img: { sourceUrl: null, sourceType: 'CUSTOM_IMAGE' }, category: 'NOTICE' };
      }

      this.voteInfo = _.cloneDeep(VOTE);
      this.voteInfo.server = this.value?.vote || {};
      this.voteInfo.base = this.voteBase;
      if (this.edit) {
        if (this.model.image) this.model.img.sourceUrl = this.model.image;
        this.isVoteInit = !this.modifyVote;
      }
    },
    async registration(modify) {
      if (this.checkedFill) {
        this.$toast('toast.mustInput', { type: 'fail' });
        return;
      }

      this.model.image = this.model.img.sourceUrl;
      if (this.voteInfo && this.voteInfo.server) this.model.voteId = this.voteInfo.server.voteId;
      this.model.link = this.resetLink(this.model.link);
      const v = modify
        ? await this.$services.channel.editFeed({ routeId: this.$route.params.id, feedId: this.model.id, body: this.model })
        : await this.$services.channel.makeFeed({ routeId: this.$route.params.id, body: this.model });
      this.model = { text: '', img: { sourceUrl: null, sourceType: 'CUSTOM_IMAGE' }, category: this.model.category };

      this.$emit('update', v);
      this.$toast(this.$t('toast.editFeed', { val: `${modify ? this.$t('toast.modify') : this.$t('toast.save')}` }), { translate: false });
    },
    resetType(id) {
      this.typeId = id;
      this.model.category = this.typeId === 0 ? 'NOTICE' : 'EVENT';
    },
    resetLink(value) {
      if (!value) return '';
      const v = value.slice(0, 4);
      if (v === 'http') return value;
      return `http://${value}`;
    },
    async removeVote() {
      await this.$services.vote.closeVote(this.voteInfo.server.voteId);
      this.voteInfo.server = null;
    },
    async openVoteModal() {
      this.voteInfo.server = await this.$modal(CreateVoteModal, { model: this.voteInfo, modify: this.modifyVote, length: 16 });
    },
  },
  mounted() {
    this.updateModel();
  },
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[feed-editor] {.w(100%);.p(22, 8, 24);.-box;.-a(rgba(0, 0, 0, 0.1));.br(8);.bgc(white);.rel; .tl;
  h3 {.c(black);.mb(23);.fs(16); .regular;}
  [tab-select] { .bgc(@asset-bg-grey); .br(8); .abs; .rt(8, 12);
    > button {.h(40); .fs(14, 40px); .br(8);
      &.on {.bgc(black); .c(white);}
    }
  }
  [text-box] {.h(144); .mt(12);}
  .link {.mt(12);}
  .bts-ctrl {.ib-list;.rel;.mt(16);
    > * {.vam;}
    > button {.wh(80, 40);.c(@title-black);.fs(14, 40px);.bgc(@asset-bg-grey);.br(20);.mr(12);
      &.btn-vote { .ml(10);}
    }
    > p {.fs(13, 20px);.c(@title-black);.abs;.rt(0, 50%);.t-yc;
      [toggle-button] {.ml(2);}
    }
  }
  .btn-opened {.wh(100%, 48);.br(12);.fs(16, 48px); .bgc(@title-black); .c(white); .mt(24);}
  .btn-cancel {width: calc(50% - 4px); .bgc(#ebebf0); .h(48);.br(12);.mt(13);.mr(8); .c(#191919);}
  .btn-confirm {width: calc(50% - 4px); .bgc(@title-black); .h(48);.br(12);.mt(13); .c(white);}
  @media (@tl-up) {
    .p(25, 20, 36);
    h3 {.fs(24);}
    [tab-select] { .rt(20, 20); }
    [text-box] {.mt(16);}
    .link {.mt(16);}
    .bts-ctrl {
      > button {.mr(16);
        &.btn-vote { .wh(100, 40);}
      }
      > p {.static;.t-y(0);.ml(20);}
    }
    .btn-opened {.w(200);.abs;.rb(20, 28);}
    .btn-cancel {.w(120);.abs;.rb(150, 32);
    }
    .btn-confirm {.w(120);.abs;.rb(20, 32);}
  }
  @media (@ds-up) {

  }
}
</style>
