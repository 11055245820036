<template>
  <div reply-input-box :class="{edit}">
    <TextBox placeholder="댓글을 입력하세요." mandatory v-model="reply" :max-length="limitLength" :readonly="!isLogin" @click="authCheck" auto-height />
    <div class="btn-holder">
      <span v-if="limitLength > 0" class="limit-char">
        <em>{{ reply.length }}</em> / {{ limitLength }}
      </span>
      <button class="btn-input" :disabled="!reply" @mousedown="replyInput">입력</button>
      <button class="btn-cancel" @mousedown="cancelInput">취소</button>
    </div>
  </div>
</template>

<script>
import { getter } from '@shared/utils/storeUtils';
import TextBox from '@shared/components/common/input/TextBox.vue';

export default {
  name: 'ReplyInputBox',
  components: { TextBox },
  data() {
    return {
      reply: '',
    };
  },
  props: {
    text: { type: String, default: '' },
    limitLength: { type: Number, default: 200 },
    edit: { type: Boolean, default: false },
  },
  computed: {
    isLogin: getter('auth', 'isLogin'),
  },
  watch: {
    text() {
      this.update();
    },
  },
  methods: {
    authCheck() {
      if (!this.isLogin) return this.$services.auth.oAuthLogin(true);
    },
    replyInput() {
      this.$emit('reply-input', this.reply);
      this.reply = '';
    },
    cancelInput() {
      this.$emit('cancel-input', false);
      this.reply = '';
    },
    update() {
      this.reply = this.text;
    },
  },
  mounted() {
    this.update();
  },
};
</script>

<style lang="less">
@import '~@/less/proj.less';

[reply-input-box] { .p(8, 0, 16);.ib-list;
  [text-box] { .w(100%); .br(8);
    .field { .min-h(40); .max-h(76); .fs(12, 18); .p(11, 14); }
    &.focus {
      ~ .btn-holder { .block; }
    }
  }
  .btn-holder { .hide; .crop; .w(100%); .mt(16);
    .limit-char { .fl; .fs(12, 18); .c(#a4a3ae);
      > em { .c(#000); }
    }
    > button { .fr; .c(white);.bgc(#191919);.br(8);.fs(12);.wh(72, 40);.ml(8); }
    .btn-input {
      &:disabled { .o(0.5); cursor: no-drop; }
    }
    .btn-cancel { .fr; .c(#191919);.bgc(#ebebf0); }
  }

  &.edit {
    .btn-holder { .block; }
  }

  @media (@tl-up) {
    .p(12, 0, 20);
    [text-box] { .br(12);
      .field { .min-h(48); .max-h(150); .fs(14, 21); .p(13.5, 18); }
    }
    .btn-holder { .block;
      .limit-char { .fs(14, 28); }
      > button { .fs(16); .wh(100, 48); .br(12); }
    }
  }
}
</style>
