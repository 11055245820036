<template>
  <div category-option>
    <h3>카테고리별 보기</h3>
    <p class="bts">
      <button :class="{on:model === '' || model === 'ALL'}" @click="click('ALL')">전체</button>
      <button :class="{on:model === 'NOTICE'}" @click="click('NOTICE')">공지</button>
      <button :class="{on:model === 'EVENT'}" @click="click('EVENT')">이벤트</button>
    </p>
  </div>
</template>

<script>
import gtag from 'shared/mixins/gtag';

export default {
  name: 'CategoryOption',
  mixins: [gtag],
  props: {
    value: String,
  },
  data() {
    return {
      model: null,
    };
  },
  computed: {
    upRouteGameId() {
      return this.$route.params.id.toUpperCase();
    },
  },
  watch: {
    value: 'updateModel',
  },
  methods: {
    updateModel() {
      this.model = this.value;
    },
    change(v) {
      this.model = v;
      this.$emit('input', this.model);
    },
    click(v) {
      this.$scroll.scrollTo(0);
      this.change(v);
      this.trackEvent(`Newsfeed_${v.toLowerCase()}`, 'click', `${this.upRouteGameId}`);
    },
  },
  created() {
    if (this.value) this.updateModel();
  },
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[category-option] { .bgc(#ebebf0); .p(20); .-box; .br(8);
  h3 {.fs(14); .mb(16); .font-spoqa;}
  .bts {
    button {.ib; .wh(80, 40); .br(50); .bgc(#d4d4d8); .fs(16, 40px); .p(0, 12); .-box; .c(#202227); .ml(14); .tc; .rel;
      &:nth-of-type(1) {.ml(0);}
      &.on { .bgc(#ec5355); .c(white);
      }
    }
  }
  @media (@tl-up) {
    .p(28, 20, 25); .rel;
    h3 {.mb(0);}
    .bts { .abs; .rt(20, 50%); .t-yc;}
  }
  @media (@ds-up) {
    h3 {.mb(20);}
    .bts {.static; .t-y(0);}
  }
}
</style>
