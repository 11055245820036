<template>
  <FeedEditor
    edit
    v-if="isEditing"
    @cancel="isEditing = false"
    :value="model"
    @update="updateFeed"
    class="feed-modify"
  />
  <PItem feed-item v-else-if="!removed && model">
    <div class="body">
      <div class="profile" :class="model.category || $route.query.category">
        <div class="type-holder">
          <span>{{ model.category === 'NOTICE' ? '공지' : '이벤트' }}</span>
          <em />
        </div>
        <div class="title-holder">
          <h4>{{ model.title }}</h4>
          <p>{{ getCreatedDate(model.createdAt) }}</p>
        </div>
      </div>
      <div class="text-holder" v-html="model.text" />
      <a class="btn-go" v-if="model.link && model.link.length" :href="model.link" target="_blank"> <em>바로 가기</em> </a>
      <div class="vote-holder" v-if="model.vote">
        <Vote :info="getVoteInfo" />
      </div>
      <p class="option-bts" v-if="owner">
        <button :class="{ active: model.isNotice }" class="btn-pin" @click="onClickPin" />
        <button class="btn-more" @click="onClickMore" />
      </p>
    </div>
    <div class="more-btn-options" v-if="moreOptionsOpen" v-click-outside="onClickMore">
      <button @click="editFeed">피드 수정</button>
      <button @click="deleteFeed">피드 삭제</button>
    </div>
    <FeedAssetArea :value="assetOptions" />
    <ReplyEditor :value="model" v-if="value.useComment" />
  </PItem>
</template>

<script>
import moment from 'moment';
import Vote from '@shared/components/vote/Vote.vue';
import { getFeedOptions, getPersonalOptions } from '@/data/mapper/getter';
import getCloudFileUrl from '@/utils/getCloudFileUrl';
import FeedAssetArea from '@/views/components/newsFeed/feed/FeedAssetArea.vue';
import FeedEditor from '@/views/components/newsFeed/feed/FeedEditor.vue';
import ReplyEditor from '@/views/components/newsFeed/feed/ReplyEditor.vue';

export default {
  name: 'FeedItem',
  components: { ReplyEditor, FeedEditor, FeedAssetArea, Vote },
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
    owner: { type: Boolean, default: false },
  },
  data() {
    return {
      moreOptionsOpen: false,
      isEditing: false,
      removed: false,
      model: null,
    };
  },
  watch: {
    isEditing() {
    },
  },
  computed: {
    getVoteInfo() {
      return {
        id: this.model.id,
        owner: this.owner,
        specialId: this.specialId,
        type: 'LEAGUE_FEED',
      };
    },
    imageAssetUrl() {
      const img = this.model.image;
      return img && img.length > 0 ? getCloudFileUrl(img) : null;
    },
    assetOptions() {
      return { img: this.imageAssetUrl };
    },
    personalOptions() {
      return () => getPersonalOptions(this.model, this.specialId);
    },
    commentOptions() {
      return { feedId: this.model.id, isChannelOwner: this.owner };
    },
    feedOptions() {
      return getFeedOptions(this.model);
    },
  },
  methods: {
    updateModel(value) {
      if (value) this.model = value;
      this.model.category = value.category?.value;
    },
    getCreatedDate(v) {
      return moment(v).format('YYYY-MM-DD');
    },
    async onClickPin() {
      const body = {
        isNotice: !this.model.isNotice,
        title: this.model.title,
        text: this.model.text,
        image: this.model.image,
        useComment: this.model.useComment,
        category: this.model.category,
        link: this.model.link,
      };
      if (this.model.vote) body.voteId = this.model.vote.voteId;
      const r = await this.$services.channel.editFeed({ routeId: this.$route.params.id, feedId: this.model.id, body });
      this.updateModel(r);
      this.$emit('update');
    },
    onClickMore() {
      this.moreOptionsOpen = !this.moreOptionsOpen;
    },
    editFeed() {
      this.isEditing = true;
      this.onClickMore();
    },
    async deleteFeed() {
      await this.$services.channel.deleteFeed({ routeId: this.$route.params.id, feedId: this.model.id });
      this.removed = true;
      this.$emit('update');
    },
    updateFeed(value) {
      this.isEditing = false;
      this.updateModel(value);
    },
  },
  mounted() {
    this.updateModel(this.value);
  },
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[feed-editor].feed-modify {.mt(20);}
[feed-item] {.bgc(white);.br(8);.crop;.rel; .min-h(168);
  .body {.p(12, 16, 0);
    .profile { .ib-list;
      .type-holder {.wh(45); .vam; .rel;
        > span {.abs; .fs(11); .c(white); .lb(50%, 5); .t-xc;}
        > em {.block; .br(6); .wh(100%);
          &:before {.cnt; .wh(15); .contain('@{icon}/announcement.svg'); .abs; .lt(50%, 7); .t-xc;}
        }
      }
      .title-holder {.vam; width: calc(100% - 130px); .ml(8);
        h4 { .fs(13, 15px); .vam; .ellipsis(2); .mb(2); .font-spoqa;}
        p {.block; .fs(11); .regular; .c(#a4a3ae);}
      }
      &.NOTICE .type-holder > em {.bgc(#5b91f5);}
      &.EVENT .type-holder > em {.bgc(#7356bf);
        &:before {.contain('@{icon}/icon-event.svg');}
      }
    }
    .option-bts {.abs;.rt(12, 12);z-index: 1;
      > button {.br(12);.bgc(@asset-bg-grey);.ml(4);.ib;.rel; .wh(32);
        &:nth-child(1) { .o(.5);
          &:before {.contain('@{icon}/Pin.svg');.wh(60%);.abs;.cnt;.lt(50%, 50%); .t-xyc;}
          &.active {.o(1);}
        }
        &:nth-child(2) {
          &:before {.contain('@{icon}/DetailDot.svg');.wh(60%);.abs;.cnt;.lt(50%, 50%); .t-xyc;}
        }
      }
    }
    .vote-holder { .mb(20);}
    .text-holder { .fs(12, 17px); .mt(15); .mb(15); white-space: pre-line;}
    .btn-go {.wh(100%, 40); .fs(13, 40px); .c(#5b91f5); .-a(#5b91f5); .block; .-box; .rel; transition: .3s; .mb(20);
      em {.abs; .lt(0, 0); .pl(26); .-box; z-index: 3; .wh(100%); .block;
        &:after {.cnt; .abs; .wh(37, 14); .contain('@{icon}/icon-arrow-go.svg'); .ib; .vam; .mt(12); .ml(8); transition: .3s;}
      }
      &:before {.cnt; .abs; .wh(10, 100%); .bgc(#5b91f5); .lt(0, 0); z-index: 2; transition: .3s;}
      &:after {.cnt; .abs; .wh(100%); .bgc(#f5f8ff); .lt(0, 0); z-index: 1;}
      &:hover { .c(white);
        em:after { .contain('@{icon}/icon-arrow-go-w.svg');}
        &:before {.w(100%);}
      }
    }
  }
  [feed-asset-area] {.mb(13);}
  [reply-editor] {.p(0, 16, 20);}

  .more-btn-options {.abs;.bgc(white);.br(12);.-b(#ebebf0);.t(50);.r(0);z-index: 2;.p(12, 8);
    > button {.block;.wh(120, 40);.c(#787781);
      &:hover {.bgc(#ebebf0);.c(#191919);}
    }
  }
  @media (@tl-up) {
    .body { .p(20, 20, 0);
      .profile {
        .type-holder {.wh(60);
          > span {.fs(13);}
          > em:before {.wh(20); .t(12);}
        }
        .title-holder {
          h4 {.fs(16, 18px); .mb(4);}
          p {.fs(12);}
        }
      }
      .option-bts { .rt(20, 20);
        > button { .wh(40);
          &:nth-child(1) {
            &:before {.wh(65%);}
          }
          &:nth-child(2) {
            &:before {.wh(65%);}
          }
        }
      }
      .text-holder {.fs(14, 21px); .mb(30);}
      .btn-go {.h(48); .fs(14, 48px); .mb(32);
        em:after {.wh(48, 16); .mt(16);}
      }
    }
    [feed-asset-area] {.mb(18);}
    [reply-editor] {.p(0, 20, 30);}
  }
}
</style>
