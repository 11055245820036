<template>
  <div reply-list>
    <div class="user">
      <Avatar
        class="image-asset"
        dimension="32x32"
        :info="user"
        userProfile
      />
      <div class="info">
        <span class="name">{{ user.nickname }}</span>
        <span class="time">{{ $fromNow(item.createdAt, 'YYYY-MM-DD HH:mm') }}</span>
      </div>
    </div>
    <div class="options-bts" @click="togglePopOver(true)" v-click-outside="togglePopOver">
      <div v-if="!editMode && (possibleEdit || possibleDelete)" class="btn-more">
        <ul v-if="popOver" class="pop-over">
          <li v-if="possibleEdit" @click="changeEditMode">수정</li>
          <li v-if="possibleDelete" @click="deleteReply">삭제</li>
        </ul>
      </div>
    </div>
    <div class="input-body" v-if="!editMode" v-html="item.text.replace(/\n/gi, '<br />')" />
    <ReplyInputBox v-else :text="item.text" :limit-length="500" @reply-input="editReply" @cancel-input="changeEditMode" edit />
  </div>
</template>

<script>
import { getter, state } from '@shared/utils/storeUtils';
import Avatar from '@shared/components/common/Avatar.vue';
import ConfirmModal from '@shared/components/common/modal/ConfirmModal.vue';
import ReplyInputBox from '@/views/components/newsFeed/feed/ReplyInputBox.vue';

export default {
  name: 'ReplyList',
  components: { Avatar, ReplyInputBox },
  data() {
    return {
      editMode: false,
      popOver: false,
    };
  },
  props: {
    item: { type: Object, required: true },
  },
  computed: {
    myInfo: state('auth', 'myInfo'),
    isLogin: getter('auth', 'isLogin'),
    user() {
      return this.item.user;
    },
    possibleEdit() {
      return this.isLogin && this.user.id === this.myInfo.id;
    },
    possibleDelete() {
      if (this.myInfo?.isOrganizer || this.myInfo?.email === 'help@bigpi.co') return true;
      return this.isLogin && this.user.id === this.myInfo.id;
    },
  },
  methods: {
    togglePopOver(status = false) {
      this.popOver = status;
    },
    changeEditMode() {
      this.togglePopOver(false);
      this.editMode = !this.editMode;
    },
    editReply(text) {
      if (text.trim().length === 0) {
        this.$toast('toast.overOne', { type: 'fail' });
        return null;
      }

      this.$emit('edit-reply', this.item.id, text);
      this.changeEditMode();
    },
    async deleteReply() {
      this.togglePopOver(false);

      const deleteMsg = this.user.id === this.myInfo.id ? '댓글을 삭제하시겠습니까?' : `${this.user.nickname} 이(가) 작성한 <br />댓글을 삭제하시겠습니까?`;

      await this.$modal(ConfirmModal, { body: deleteMsg, showEmphasisText: false, buttonName: '확인', className: 'reply-confirm' });

      this.$emit('delete-reply', this.item.id);
    },
  },
};
</script>

<style lang="less">
@import '~@/less/proj.less';

[reply-list] { .p(13, 0);.rel;.-b(@asset-bg-grey);
  .user {.ib-list;
    > * {.vam;.mr(6);}
    > .image-asset {.wh(32); .mb(0); .br(16);}
    .info {
      .name { .block; .fs(13); .bold; .c(black); }
      .time { .block; .fs(12); .c(rgba(0, 0, 0, 0.4)); }
    }
  }
  .options-bts {.ib-list;.abs;.rt(0, 13);
    > button {.wh(24);.bgc(@asset-bg-grey);.vam;.ml(6);}
    .btn-modify {.br(24);.w(50);.fs(13, 24px);.c(@input-text-grey);
      &:hover {.c(@title-black);}
    }
    .btn-remove {.br(6);.rel;
      &:before {.contain('@{icon}/black_trash.svg');.cnt;.abs;.lt(1, 3.5);.wh(23, 22);.o(0.4);}
      &:hover {
        &:before {.o(1);}
      }
    }
    .btn-more { .rel; .ib; .wh(32); .ml(6); .vam; .bgc(@asset-bg-grey); .br(12); .pointer;
      &::before { .cnt; .wh(19, 100%); .mh-c; .contain('/img/pages/icon/DetailDot.svg'); .bg-c; .o(0.3); }
      &:hover {
        &::before { .o(0.7); }
        //.pop-over { .block; }
      }
      .pop-over { .abs; .rt(0, 32); .z(1); .w(103); .p(10, 0); .bgc(#fff); .br(12); filter: drop-shadow(0px 0px 1px rgba(0, 0, 0, 0.05)) drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.1));
        > li { .p(10, 0); .fs(14, 21); .c(#787781); .tc; .pointer;
          &:hover { .bgc(#fafafa); }
        }
      }
    }
  }
  .input-body {.c(black);.fs(14);.mt(11);}
  [reply-input-box] { .p(0); .mt(8); }

  @media (@tp-up) {
    .options-bts {
      .btn-more { .wh(33);
        &::before { .w(21); }
      }
    }
    .input-body { .mt(10); .fs(14); }
  }
}

.reply-confirm {
  &[confirm-modal] {
    > .panel { .max-w(335); .p(16);
      > .header { .hide; }
      .scroll-area { .p(0);
        .body { .pb(0);
          > div {
            > p { .flex; .justify-center; .items-center; .h(136); .fs(16, 26); }
          }
          .bts { .mt(0);
            > button { .h(48); .fs(16); .br(12); }
          }
        }
      }
    }
  }
}
</style>
