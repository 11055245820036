<template>
  <PSection news-feed>
    <SectionTitle title-ko="공지/이벤트" black sm />
    <article class="left-holder" v-prx:sticky="{ MS: 'no', DS: 100 }">
      <CategoryOption v-model="category" />
      <Commercial />
    </article>
    <div class="feed-list">
      <FeedEditor v-if="base && base.private && base.private.isOrganizedByMe" @update="updateList(0)" :noVote="noVote" />
      <template v-if="feedList && feedList.length">
        <FeedItem
          v-for="item in feedList"
          :key="item.id"
          :value="item"
          :owner="getOwner(item.user.nickname)"
          @update="updateList(0)"
        />
      </template>
      <button class="btn more" v-if="hasNext" @click="addList">더보기</button>
    </div>
  </PSection>
</template>

<script>
import { getter, state } from '@shared/utils/storeUtils';
import CategoryOption from '@/views/components/newsFeed/CategoryOption.vue';
import Commercial from '@/views/components/newsFeed/Commercial.vue';
import FeedEditor from '@/views/components/newsFeed/feed/FeedEditor.vue';
import FeedItem from '@/views/components/newsFeed/feed/FeedItem.vue';
import meta from '@/data/meta/index';
import SectionTitle from '@/views/components/common/SectionTitle.vue';

export default {
  name: 'NewsFeed',
  components: { SectionTitle, FeedItem, FeedEditor, Commercial, CategoryOption },
  data() {
    return {
      base: null,
      feedList: null,
      hasNext: false,
      totalCount: 0,
      cursor: 0,
      ly: 0,
    };
  },
  computed: {
    myInfo: state('auth', 'myInfo'),
    isLogin: getter('auth', 'isLogin'),
    listSize() {
      const len = (this.cursor + 1) * 10;
      return len > this.totalCount ? this.totalCount : len;
    },
    category: {
      get() {
        return this.$route.query.category || 'ALL';
      },
      set(v) {
        this.cursor = 0;
        if (this.category !== v) {
          this.$router.replace({ query: { category: v === 'ALL' ? undefined : v } });
        }
      },
    },
    routeGameId() {
      return this.$route.params.id;
    },
    noVote() {
      return ['lol', 'wr', 'val'].includes(this.routeGameId);
    },
  },
  watch: {},
  methods: {
    getOwner(name) {
      return name === this.myInfo?.nickname;
    },
    async addList() {
      this.cursor += 1;
      await this.updateList(this.cursor);
    },
    async updatePrivate() {
      this.base = await this.$services.channel.getFeedMeta(this.routeGameId);
    },
    async updateList(v) {
      const r = await this.$services.channel.getChannelFeed({
        category: this.category,
        routeId: this.routeGameId,
        cursor: v,
        size: 10,
      });
      this.feedList = v === 0 ? r.items : (this.feedList = [...this.feedList, ...r.items]);
      this.hasNext = r.hasNext;
      this.totalCount = r.totalCount;
      if (v < this.cursor) await this.updateList(v + 1);
    },
  },
  async asyncData({ route, services }) {
    const category = route.query.category || 'ALL';
    const list = await services.channel.getChannelFeed({ category, routeId: route.params.id, cursor: 0, size: 10 });
    return { feedList: list.items, hasNext: list.hasNext, totalCount: list.totalCount, category };
  },
  mounted() {
    this.updatePrivate();
  },
  metaInfo() {
    return meta[this.$route.params.id][this.$route.name];
  },
};
</script>

<style lang="less">
@import '~@/less/proj.less';

[news-feed] { .p(40, 20, 60); .min-h(500);
  [section-title] {
    .ko {.mb(20); .bold; .font-g; .fs(20, 20); .ml(0);}
  }
  .left-holder { .mb(40);
    &.pc {.hide;}
    [commercial] {.mt(20);}
  }
  .feed-list { .bgc(#ebebf0);.br(8); .p(12, 12, 20); .tc;
    [feed-item] {.mt(20); .tl;
      &:nth-of-type(1) {.mt(20);}
    }
    .btn.more {.mh-c; .mt(20);}
  }

  @media (@tp-up) {
    .w(710); .mh-c;
  }

  @media (@tl-up) {
    .w(966); .p(60, 0, 120);
    [section-title] {
      .ko {.mb(40); .fs(32, 32); }
    }
    .feed-list {.p(20, 30, 20);
    }
  }
  @media (@ds-up) {
    .clear; .w(1200); .rel;
    [section-title] h2 {}
    .left-holder {.fl; .w(308); .mb(0);}
    .feed-list {.fr; .w(828); .p(20);}
  }
}
</style>
