import _trim from 'lodash/trim';
import { BASEURL as CLOUD_BASE_URL } from '@/constants/awsConst';

const getCloudFileUrl = cloudFilePath => {
  const trimmedBaseUrl = _trim(CLOUD_BASE_URL, '/');
  const trimmedPath = _trim(cloudFilePath, '/');
  return [trimmedBaseUrl, trimmedPath].join('/');
};

export default getCloudFileUrl;
